<template>
    <!-- 2023-08-03 张晓瑜新增领取详情 -->
    <section class="activity-management">
        <div class="top-bar bg-white">
            <el-input v-model="formSearch.promotionName" placeholder="请输入活动名称" style="margin: 0 20px; width: 200px" size="small"/>
            <el-input v-model="formSearch.couponName" placeholder="请输入优惠券名称" style="margin: 0 20px; width: 200px" size="small"/>
            <el-input v-model="formSearch.leasorName" placeholder="请输入领取人名称" style="margin: 0 20px; width: 200px" size="small"/>
            <el-input v-model="formSearch.phone" placeholder="请输入手机号" style="margin: 0 20px; width: 200px" size="small"/>
            <el-select v-model="formSearch.status" placeholder="请选择状态" style="margin-right: 10px;width: 200px" size="small">
                <el-option label="全部" :value="-1"/>
                <el-option label="有效" :value="0"/>
                <el-option label="无效" :value="1"/>
                <el-option label="已使用" :value="2"/>

            </el-select>
            <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
            <el-button size="small" @click="handleReset">重置</el-button>
        </div>
        <!-- 领取详情列表(查询所有已下发的优惠券) -->
        <r-e-table class="bg-white" ref="orderTableRef" :dataRequest="getLssueCouponsListTableData" :columns="tableColumn"
                   :query="formSearch" :height="790">
            <template slot="empty">
                <el-empty/>
            </template>
        </r-e-table>
    </section>
</template>

<script>
import { tableColumn } from "./data";
import { getLssueCouponsListAPI } from "@/api/activity-management";
export default {
    name: "claim-details",
    data() {
        return {
            tableColumn, // 表格数据
            formSearch: {
                promotionName: null, //活动名称
                couponName: null, // 优惠券名称
                leasorName:null, // 领取人
                phone:null, // 手机号
                status: null, // 状态 优惠券状态 0有效 1无效 3已使用
            },
        };
    },
    mounted() {

    },
    components: {
       
    },
    methods: {
        // 获取已下发的优惠券列表信息
        getLssueCouponsListTableData(params) {
            return getLssueCouponsListAPI(params);
        },
        // 查询
        handleSearch() {
            this.$refs["orderTableRef"].getTableData();
        },
        // 重置
        handleReset(){
            this.formSearch = {promotionName: null, couponName: null,leasorName:null,phone:null, status: null,};
            Promise.resolve(this.formSearch).then(() => this.handleSearch());
        }
    },
    watch: {
    }
}
</script>


<style lang="scss" scoped>
.activity-management {
    padding: VH(15px) VW(15px);

    .top-bar {
        display: flex;
        margin: VH(10px) 0 VH(25px);
        padding: VH(15px) VW(15px);
        justify-content: flex-start;
    }

    /deep/ .r-e-table {
        .el-table .cell {
            // white-space: nowrap;
        }
    }
}
</style>